import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function TalkReceiptButton({ link }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <Button color="blue-gray" onClick={handleClick}>
      View Receipt
    </Button>
  );
}

export default TalkReceiptButton;
