import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import menuSections from "./MenuSection";
import Divider from "../../../common/shared/Divider";
import GLCSection from "./GlcSection";
import checkYear from "../../../utils/yearCheck";
import BlackCard from "./BlackCard";
import PupilageIcon from "../../../assets/PupilageIcon";
import sticker_vector from "../../../assets/vectors/sticker_vector.svg";
import externalSection from "./ExternalSection";
import ExternalCard from "./ExternalCard";
import robot from "../../../assets/vectors/robot.svg";
import award from "../../../assets/vectors/award.svg";

function CardItem({ path, name, description, btn, icon }) {
  const navigate = useNavigate();

  return (
    <Card className="w-full h-full flex justify-center items-center">
      <CardBody className="flex flex-col pt-10 pb-6 justify-between w-full h-full items-center text-center">
        <img className="mx-auto mb-6 w-[4rem] h-[4rem]" src={icon} alt={name} />
        <Typography variant="h5" color="blue-gray">
          {name}
        </Typography>
        <Typography>{description}</Typography>
        <Button onClick={() => navigate(path)} fullWidth className="mt-10">
          {btn}
        </Button>
      </CardBody>
    </Card>
  );
}

function ServiceCard() {
  const user = useSelector((state) => state.auth?.userData);

  return (
    <div className="max-w-[1200px] m-auto px-4">
      {user.isGLC &&
        GLCSection.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <Divider title={section.title} color={section.color} variant="h4" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
              {section.menu.map((menuItem, menuItemIndex) => (
                <CardItem key={menuItemIndex} {...menuItem} />
              ))}
            </div>
          </div>
        ))}

      <>
        <Divider title="Training Section" color="black" variant="h4" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
          {/* <CardItem
            path="/home/talk"
            name="Honouring 50+ Years At The Bar"
            description="Recognizing our esteemed Seniors who have dedicated 50 years and above to the legal profession"
            btn="Register To Participate"
            icon={award}
          /> */}

          <CardItem
            path="/home/training"
            name="AI Training"
            description="Adopting AI-Powered Tools, Transforming Legal Practice Today"
            btn="Register To Participate"
            icon={robot}
          />
        </div>
      </>

      <div>
        <Divider title="Applications" color="blue" variant="h4" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
          {checkYear(user.date_of_call) && (
            <BlackCard
              title="Pupillage Request Approval"
              desc="View all pupillage request."
              link="/home/pupilage/request"
              btnTitle="View"
              icon={<PupilageIcon />}
            />
          )}
          <BlackCard
            title="Sticker Application"
            desc="Apply or view Sticker application status"
            link="/home/sticker"
            btnTitle="Apply / View"
            icon={
              <>
                <img
                  className="mx-auto mb-6 w-[4rem] h-[4rem]"
                  src={sticker_vector}
                  alt="sticker"
                />
              </>
            }
          />
        </div>
      </div>

      {/* 9270 */}

      {menuSections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <Divider title={section.title} color={section.color} variant="h4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
            {section.menu.map((menuItem, menuItemIndex) => (
              <CardItem key={menuItemIndex} {...menuItem} />
            ))}
          </div>
        </div>
      ))}

      {externalSection.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <Divider title={section.title} color={section.color} variant="h4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 lg:gap-[3rem] mt-10 w-full">
            {section.menu.map((menuItem, menuItemIndex) => (
              <ExternalCard
                key={menuItemIndex}
                title={menuItem.name}
                link={menuItem.path}
                btnTitle={menuItem.btnTitle}
                icon={menuItem.icon}
                disable={menuItem.disable}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServiceCard;
