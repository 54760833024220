import * as types from "../constants/talk_constants";

const initialState = {
  data: null,
  talkError: null,
};

const talkReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_TALK_SUCCESS:
    case types.USER_TALK_PAYMENT_SUCCESS:
    case types.USER_TALK_RECEIPT_SUCCESS:
      return { ...state, data: payload ? payload : null, talkError: null };

    case types.USER_TALK_PAYMENT_FAILURE:
    case types.GET_USER_TALK_FAILURE:
    case types.USER_TALK_RECEIPT_FAILURE:
      return { ...state, talkError: payload ? payload : null };

    default:
      return state;
  }
};

export default talkReducer;
