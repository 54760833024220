import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import FallbackLoading from "./common/loader/FallbackLoading";
import { publicRoutes } from "./routes/public.routes";
import { privateRoutes } from "./routes/private.routes";
import ProctectedRoutes from "./routes/proctected.routes";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { useInactivityRedirect } from "./utils/redirect";

function App() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [id, setId] = useState(false);

  // const token = searchParams.get("gba");
  const userData = useSelector((state) => state.auth?.userData);
  // const currentSeconds = Math.floor(Date.now() / 1000);

  // useEffect(() => {
  //   if (token) {
  //     if (currentSeconds < token) {
  //       setId(true);
  //     } else {
  //       setId(false);
  //       window.location.href = "https://www.ghanabar.org";
  //     }
  //   } else {
  //     setId(false);
  //     window.location.href = "https://www.ghanabar.org";
  //   }
  // }, [token, currentSeconds]);

  // if (id)

  useInactivityRedirect();

  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Routes>
          <Route element={<ProctectedRoutes userData={userData} />}>
            {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Route>

          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Suspense>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{ style: { background: "#363636", color: "#fff" } }}
      />
    </>
  );
}

export default App;
