import createAsyncAction from "../../utils/asyncAction";
import * as api from "../api/talk/talk_api";
import * as types from "../constants/talk_constants";

const getUserTalk = {
  success: types.GET_USER_TALK_SUCCESS,
  failure: types.GET_USER_TALK_FAILURE,
};

const userTalkPayment = {
  success: types.USER_TALK_PAYMENT_SUCCESS,
  failure: types.USER_TALK_PAYMENT_FAILURE,
};

const userTalkReceipt = {
  success: types.USER_TALK_RECEIPT_SUCCESS,
  failure: types.USER_TALK_RECEIPT_FAILURE,
};

export const getUserTalkAction = createAsyncAction(
  api.getUserTalk,
  getUserTalk
);

export const userTalkPaymentAction = createAsyncAction(
  api.userTalkPayment,
  userTalkPayment
);

export const userTalkReceiptAction = createAsyncAction(
  api.userTalkReceipt,
  userTalkReceipt
);
