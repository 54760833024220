import React from "react";
import TalkPayment from "./TalkPayment";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import TrainingTag from "../../training/components/TrainingTag";
import DetailItem from "../../../common/shared/DetailItem";
import TalkReceiptButton from "./TalkReceiptButton";

function TalkList({ row }) {
  return (
    row &&
    row.map((data) => (
      <div key={data.id} className="card-wrapper">
        {!data.isPaid && <TalkPayment data={data} />}
        <Card>
          <CardBody>
            <TrainingTag data={data.isPaid} />

            <Typography variant="h5" color="blue-gray" className="mb-4">
              {data.name}
            </Typography>

            <DetailItem title="Description" item={data?.meta?.description} />

            <DetailItem title="Venue" item={data?.meta?.venue} />

            <DetailItem
              title="Date"
              item={`${data?.meta?.date} - ${data?.meta?.time}`}
            />

            <DetailItem title="Amount" item={`GH¢${data.amount.toFixed(2)}`} />
          </CardBody>
          <CardFooter>
            {data.isPaid ? (
              <div className="flex flex-col gap-4 md:flex-row md:items-center">
                <TalkReceiptButton
                  link={`/home/talk/receipt/${data.receipt?.id}`}
                />
              </div>
            ) : (
              <TalkPayment data={data} />
            )}
          </CardFooter>
        </Card>
      </div>
    ))
  );
}

export default TalkList;
