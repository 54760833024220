import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTalkAction } from "../../redux/actions/talk_action";
import SectionTitle from "../../common/shared/SectionTitle";
import Loader from "../../common/loader/Loader";
import NoContent from "../../common/shared/NoContent";
import TalkList from "./components/TalkList";

const link = [{ name: "Talk", link: "" }];

function Talk() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.talk);

  useEffect(() => {
    dispatch(getUserTalkAction()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle title="Honoring Seniors" children={link} description="" />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {loading ? (
          <Loader />
        ) : data.talk?.length === 0 ? (
          <NoContent message="No Training Available Yet." />
        ) : (
          <TalkList row={data?.talk} />
        )}
      </div>
    </>
  );
}

export default Talk;
