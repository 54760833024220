import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function TalkPayment({ data }) {
  const navigate = useNavigate();
  const formData = { data };

  return (
    <Button
      color="pink"
      onClick={() => navigate("/home/talk/invoice", { state: formData })}
    >
      Make Payment
    </Button>
  );
}

export default TalkPayment;
