import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserStickers } from "../../redux/actions/stickers_action";
import SectionTitle from "../../common/shared/SectionTitle";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loader/Loader";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import NoContent from "../../common/shared/NoContent";
import StickerPay from "./components/StickerPay";

const link = [{ name: "Sticker Applications" }];

function Stickers() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, stickerError } = useSelector((state) => state.sticker);

  const handleNew = () => {
    navigate("/home/new/stickers");
  };

  const handleEdit = (item) => {
    navigate(`/home/stickers/edit/${item}`);
  };

  useEffect(() => {
    dispatch(getUserStickers()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      <SectionTitle
        title="Stickers Applications"
        description=""
        children={link}
        link="/home"
      />

      <div className="my-8 max-w-[1200px] m-auto px-4">
        {stickerError && <AlertMessage type="error" message={stickerError} />}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Button color="pink" className="mb-4" onClick={handleNew}>
              Apply for Sticker
            </Button>
            {data.length === 0 ? (
              <NoContent message="No Data to display" />
            ) : (
              data.map((item, index) => (
                <Card key={index} className="mb-6">
                  <CardBody>
                    <Typography variant="h5" color="black">
                      Sticker request for {item.plate_a} - {item.plate_b} -{" "}
                      {item.plate_c}
                    </Typography>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
                      <div>
                        <span>Quantity</span>
                        <Typography variant="h5" color="black">
                          {item?.qty}
                        </Typography>
                      </div>

                      <div>
                        <span>Amount</span>
                        <Typography variant="h5" color="black">
                          GH¢ {item?.qty * 25}
                        </Typography>
                      </div>

                      <div>
                        <span>Status</span>
                        <Typography variant="h5" color="black">
                          {item?.status === 0 ? "Not Paid" : "Paid"}
                        </Typography>
                      </div>
                    </div>

                    {item?.notes ? (
                      <>
                        <span>Rejected</span>
                        <Typography color="red" variant="lead">
                          {item?.notes}
                        </Typography>
                      </>
                    ) : item.action ? (
                      <>
                        <span>Status</span>
                        <Typography color="green" variant="lead">
                          Completed
                        </Typography>
                      </>
                    ) : (
                      <>
                        <span>Status</span>
                        <Typography color="black" variant="lead">
                          {item.isApproved === 0 && item.status === 0
                            ? "Please make payment"
                            : item.isApproved === 0 && item.status === 1
                            ? "Pending. Yet to be reviewed"
                            : `Sticker application approved. Please contact the secretariat to check if sticker is ready for pickup.`}
                        </Typography>
                      </>
                    )}
                  </CardBody>

                  <CardFooter className="flex item-center gap-3">
                    {item.isApproved === 0 && (
                      <Button
                        color="blue-gray"
                        onClick={() => handleEdit(item.id)}
                      >
                        Edit Details
                      </Button>
                    )}
                    {item.status === 0 && <StickerPay id={item.id} />}
                  </CardFooter>
                </Card>
              ))
            )}
          </>
        )}
      </div>
    </>
  );
}
export default Stickers;
