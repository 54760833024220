import { useEffect } from "react";
import { INACTIVITY_LIMIT } from "../constants/constants";

export const useInactivityRedirect = () => {
  useEffect(() => {
    let timeout;

    const resetTimer = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        localStorage.removeItem("profile");
        window.location.href = "https://ghanabar.org";
      }, INACTIVITY_LIMIT);
    };

    const event = [
      "keydown",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    event.forEach((e) => window.addEventListener(e, resetTimer));
    resetTimer();

    return () => {
      clearTimeout(timeout);
      event.forEach((e) => window.removeEventListener(e, resetTimer));
    };
  }, []);
};
