import {
  GET_USER_TALK,
  TALK_RECEIPT,
  USER_TALK_PAYMENT,
} from "../../constants/api_constants";
import { handleApi } from "../utils";

export const getUserTalk = async () => {
  return handleApi("get", GET_USER_TALK);
};

export const userTalkPayment = async (values) => {
  return handleApi("post", USER_TALK_PAYMENT, values);
};

export const userTalkReceipt = async (id) => {
  return handleApi("get", `${TALK_RECEIPT}/${id}`);
};
